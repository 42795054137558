import React from 'react';
import { graphql } from 'gatsby';

export default ({ data }) => {
  console.log('kek');
  console.log(data);

  return <div>hello world</div>;
};

export const query = graphql`
  query {
    allWpPost {
      edges {
        node {
          id
          status
          slug
          content
        }
      }
    }
  }
`;
